@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
@font-face {
  font-family: "Rudaw-Regular";
  src: local("Rudaw-Regular"),
    url("./fonts/rudawregular2.ttf") format("truetype");
  font-weight: Regular;
  font-display: swap;
}
@font-face {
  font-family: "Rudaw-Bold";
  src: local("Rudaw-Bold"), url("./fonts/rudawbold.ttf") format("truetype");
  /* font-weight: bold; */
  font-display: swap;
}

body {
  margin: 0;
  /* font-family: "Montserrat", sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* tbody{
    display:block;
    border: 2px solid black;
    border-collapse: separate;
    border-spacing: 4px; 
  } */
.rtl-grid {
  direction: ltr;
}
/* Users Pagination */
.pagination {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  cursor: pointer;
  margin-top: 1rem;
  align-self: center;
  padding-bottom: 20px;
}

.pagination a {
  padding-left: 10px;
  border: 1px solid rgba(128, 128, 128, 0.332);
  color: #373e4a;
  opacity: 0.7;
  font-size: 14px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.pagination__link {
  font-weight: bold;
  font-size: 14px;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.pagination__link--active {
  color: #fff !important;
  background: #0638a5;
}
.pagination__link--active a {
  color: #fff;
}

input[type="date"] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
}

.models-container {
  padding: 20px;
  /* border: 1px solid #ccc;
    border-radius: 4px; */
}

.models-container input {
  margin-right: 8px;
}

.models-container button {
  margin: 8px;
  padding: 8px 16px;
  background-color: #0077ff;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.models-container button:hover {
  background-color: #0055cc;
}

.models-container button:active {
  background-color: #003399;
}

.my-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
  max-width: 100%;
}

.my-table thead th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: 500;
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;
}

.my-table tbody td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;
}

.my-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");

:root {
  --primary-color: #090e14;
  --text: white;
  --sidebar-gray: #111926;
  --sidebar-gray-light: #d8dce0;
  --sidebar-gray-background: #373e4a;
  --success: #00c896;
}

nav {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100vh;
  background-color: var(--primary-color);
  width: 17rem;
  overflow-x: hidden;
  padding: 1.8rem 0.85rem;
  color: var(--text);
  display: flex;
  flex-direction: column;
  transition: width 0.5s ease-in-out;
}
@media (max-width: 639px) {
  nav {
    position: relative;
    width: 100%;
    height: auto;
    padding: 1.8rem 0.85rem;
  }
}
main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.collapsed {
  width: 80px;
}

.collapsed .hide {
  opacity: 0;
  position: absolute;
  display: none;
  pointer-events: none;
  transition: all 0.1s ease-in-out;
}

/*? sidebar top */

.sidebar-top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 32px;
}

.collapsed .sidebar-top {
  display: flex;
  flex-direction: column;
  padding: 0px;
  justify-content: center;
}

@media (max-width: 639px) {
  .collapsed .sidebar-top {
    display: flex;
    flex-direction: row;
    padding: 0px;
    justify-content: space-between;
  }
  .toggle {
    height: auto !important;
    position: absolute;
    top: 0;
    width: 100% !important;
    z-index: 100;
  }
}

.logo__wrapper {
  padding-left: 4px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
}

.logo {
  width: 54px;
  height: 54px;
}

.expand-btn {
  position: relative;
  display: grid;
  place-items: center;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s;
}

.expand-btn svg {
  stroke: white;
  transform: rotate(180deg);
  width: 33px;
  height: 33px;
}

.collapsed .expand-btn svg {
  margin-top: 8px;
  transform: rotate(360deg);
  width: 33px;
  height: 33px;
}

/*? menu links */

.sidebar-links ul {
  list-style-type: none;
  position: relative;
}

.sidebar-links li {
  position: relative;
}

.sidebar-links li a {
  margin: 8px 0px;
  padding: 12px;
  color: var(--sidebar-gray-light);
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  min-height: 56px;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

.sidebar-links li a svg {
  color: var(--sidebar-gray-light);
  height: 23px;
  width: 23px;
}

.sidebar-links li a:hover svg {
  stroke: var(--sidebar-gray-light);
}

.sidebar-links .link {
  margin-left: 30px;
  opacity: 1;
}

.sidebar-links li a:hover,
.sidebar-links .active {
  width: 100%;
  text-decoration: none;
  background-color: var(--sidebar-gray-background);
  transition: all 100ms ease-in;
  border-radius: 10px;
  color: var(--sidebar-gray-light);
}

.sidebar-links .active {
  color: white;
}

.sidebar-links .active svg {
  stroke: white;
}

/*? bottom sidebar */

.sidebar-bottom {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: auto;
}

.divider {
  width: 100%;
  background-color: var(--sidebar-gray-light);
  height: 1px;
  display: block;
}

.sidebar-bottom ul {
  margin: 6px 0px;
}

/*? account part */

.sidebar__profile {
  display: flex;
  align-items: center;
  padding-left: 2px;
  gap: 20px;
  flex-direction: row;
  margin-top: 16px;
}

.avatar__wrapper {
  position: relative;
  display: flex;
}

.avatar {
  display: block;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
  filter: drop-shadow(-20px 0 10px rgba(0, 0, 0, 0.1));
}

.avatar:hover {
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}

.avatar__name {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.user-name {
  font-size: 0.95rem;
  font-weight: 700;
  text-align: left;
}

.email {
  font-size: 0.9rem;
}

.online__status {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--success);
  bottom: 3px;
  right: 3px;
}

.loader {
  width: 8em;
  height: 8em;
}

.loader__eye1,
.loader__eye2,
.loader__mouth1,
.loader__mouth2 {
  animation: eye1 3s ease-in-out infinite;
}

.loader__eye1,
.loader__eye2 {
  transform-origin: 64px 64px;
}

.loader__eye2 {
  animation-name: eye2;
}

.loader__mouth1 {
  animation-name: mouth1;
}

.loader__mouth2 {
  animation-name: mouth2;
  visibility: hidden;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 90%, 10%);
    --fg: hsl(var(--hue), 90%, 90%);
  }
}

@keyframes eye1 {
  from {
    transform: rotate(-260deg) translate(0, -56px);
  }

  50%,
  60% {
    animation-timing-function: cubic-bezier(0.17, 0, 0.58, 1);
    transform: rotate(-40deg) translate(0, -56px) scale(1);
  }

  to {
    transform: rotate(225deg) translate(0, -56px) scale(0.35);
  }
}

@keyframes eye2 {
  from {
    transform: rotate(-260deg) translate(0, -56px);
  }

  50% {
    transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1);
  }

  52.5% {
    transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1, 0);
  }

  55%,
  70% {
    animation-timing-function: cubic-bezier(0, 0, 0.28, 1);
    transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1);
  }

  to {
    transform: rotate(150deg) translate(0, -56px) scale(0.4);
  }
}

@keyframes eyeBlink {
  from,
  25%,
  75%,
  to {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0);
  }
}

@keyframes mouth1 {
  from {
    animation-timing-function: ease-in;
    stroke-dasharray: 0 351.86;
    stroke-dashoffset: 0;
  }

  25% {
    animation-timing-function: ease-out;
    stroke-dasharray: 175.93 351.86;
    stroke-dashoffset: 0;
  }

  50% {
    animation-timing-function: steps(1, start);
    stroke-dasharray: 175.93 351.86;
    stroke-dashoffset: -175.93;
    visibility: visible;
  }

  75%,
  to {
    visibility: hidden;
  }
}

@keyframes mouth2 {
  from {
    animation-timing-function: steps(1, end);
    visibility: hidden;
  }

  50% {
    animation-timing-function: ease-in-out;
    visibility: visible;
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -351.86;
  }
}
